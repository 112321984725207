/** @format */

import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import SEO from '../components/SEO';
import styles from '../css/faq-css-modules.module.css';
import Banner from '../components/Banner';
import Layout from '../components/layout/Layout';
import { Row, SectionWrapper, SectionInnerWrapper, AlignRight } from '../components/Wrapper';

import { faq } from '../data/dummy-data';

const FaqPage = ({ data }) => {
    const [isOpened, setIsOpened] = useState(
        faq.map((x) => {
            return false;
        })
    );

    return (
        <Layout>
            <SEO title={`All Ins Agency - FAQ`} description={`All Ins Agency`} />
            <Banner heading="FREQUENTLY ASKED QUESTIONS" subHeading="WE WILL HELP YOU" trail="FAQ" trailLink="/faq" />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <div className="flex flex-col w-full px-0 md:px-4">
                        {faq.map((x, i) => (
                            <div key={i} className={styles.faqWrapper}>
                                <button
                                    className={styles.questionWrapper}
                                    onClick={() =>
                                        setIsOpened(
                                            [...isOpened].map((x, isOpenedIndex) => {
                                                if (i === isOpenedIndex) return !x;
                                                else return x;
                                            })
                                        )
                                    }>
                                    <Row>
                                        <span className={styles.num}>{`${i + 1}`}</span>
                                        <span>{x.questeion}</span>
                                        {x.important && <span className={styles.importantLabel}>important!</span>}
                                        <AlignRight className="flex-1">
                                            {isOpened[i] ? (
                                                <div className={styles.iconWrapper}>
                                                    <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
                                                </div>
                                            ) : (
                                                <div className={styles.iconWrapper}>
                                                    <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                </div>
                                            )}
                                        </AlignRight>
                                    </Row>
                                </button>
                                <Collapse isOpened={isOpened[i]}>
                                    <div className={styles.answerWrapper}>
                                        {x.answer}
                                        <span>
                                            {' '}
                                            <a href={x.link}>{x.link}</a>
                                        </span>
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                </SectionInnerWrapper>
            </SectionWrapper>
        </Layout>
    );
};

export default FaqPage;
