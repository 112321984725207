/** @format */

export default [
    {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        content:
            'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
        customerName: 'Jane Doe',
        customerTitle: 'Director of Research and Data',
        customerImage: 'https://placeimg.com/150/150/people',
    },
    {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        content:
            'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
        customerName: 'John Doe',
        customerTitle: 'Director of Research and Data',
        customerImage: 'https://placeimg.com/150/150/people',
    },
    {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        content:
            'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
        customerName: 'Jane Smith',
        customerTitle: 'Director of Research and Data',
        customerImage: 'https://placeimg.com/150/150/people',
    },
];

export const testimonials = [
    {
        quote: 'Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.',
        name: 'John Doe',
        position: 'Agent, Alico Agency 1',
    },
    {
        quote: 'Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.',
        name: 'John Doe',
        position: 'Agent, Alico Agency 2',
    },
    {
        quote: 'Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.',
        name: 'John Doe',
        position: 'Agent, Alico Agency 3',
    },
    {
        quote: 'Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.',
        name: 'John Doe',
        position: 'Agent, Alico Agency 4',
    },
];

export const clients = [
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
];

export const awards = [
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
    {
        logoUrl: '',
    },
];

export const faq = [
    {
        questeion: 'About MetLife',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'Life Insurance',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: true,
    },
    {
        questeion: 'Accident & Health Insurance',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'Insurance through your Employer',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'Am I eligible for insurance?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
    },
    {
        questeion: 'Can I afford insurance?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'How do I apply for insurance?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'How do I manage my policy?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'How do I make Premium Payment?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
    {
        questeion: 'How do I make a claim?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        link: 'https://www.tokiomarine.com/sg/en/personal/resources/faq.html',
        important: false,
    },
];

export const contactNumber = '+65 9999 8888';
export const contactAddress1 = '22 Sin Ming Lane';
export const contactAddress2 = '#05-78 Midview City';
export const contactPostalCode = 'Singapore 573969';
export const contactEmail = 'allins@cyder.com.sg';
export const contactWebsite = 'www.allinsagency.com';
